import { cn } from "@/lib/utils";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "./ui/Card";

type AuthCardProps = {
    title: string;
    description: string;
} & React.ComponentPropsWithoutRef<"div">;
function AuthCard({ title, description, children, className }: AuthCardProps) {
    return (
        <Card className="w-full py-6 sm:px-12 ">
            <CardHeader className="items-center">
                <CardTitle className="text-2xl text-center">{title}</CardTitle>
                <CardDescription className="text-center">
                    {description}
                </CardDescription>
            </CardHeader>
            <CardContent className={cn("flex flex-col gap-4", className)}>
                {children}
            </CardContent>
        </Card>
    );
}

export default AuthCard;
